import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DaifugoRules, { GameRules } from 'shared/game_logic/gamerules';
import { List, ListItem, ListItemText, Switch, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

interface UsernameDialogProps {
  open: boolean;
  handleCreate: (username: string, gameRules: DaifugoRules) => void;
  handleJoin: (username: string) => void;
}

function checkUsername(username: string): boolean {
  return username.length > 0 && username.match(/^[a-zA-Z0-9あ-んア-ン一-龠_]{3,20}$/) !== null;
}

function CreateOrJoinDialog({ open, handleCreate, handleJoin }: UsernameDialogProps) {
  const [username, setUsername] = useState('');
  const [n5skip, setN5skip] = useState(true);
  const [n7give, setN7give] = useState(false);
  const [n8clear, setN8clear] = useState(true);
  const [n9reverse, setN9reverse] = useState(true);
  const [n10burn, setN10burn] = useState(false);
  const [n11back, setN11back] = useState(true);
  const [joker, setJoker] = useState(true);
  const [tightSuits, setTightSuits] = useState(true);
  const [tightRank, setTightRank] = useState(true);
  const [revolution, setRevolution] = useState(false);
  const [tab, setTab] = useState('join');
  const [error, setError] = useState('');

  return (
    <Dialog open={open}>
      <DialogContent>
        <TabContext value={tab}>
          <TabList onChange={(e, value) => { setTab(value); setError(''); }}>
            <Tab label="Join" value="join" />
            <Tab label="Create" value="create" />
          </TabList>
          <TabPanel value='join'>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkUsername(username)) {
                    setError('');
                    handleJoin(username);
                  } else {
                    setError('Username must be between 3 and 20 characters and contain only alphanumeric characters and underscores');
                  }
                }
              }}
            />
            {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
          </TabPanel>
          <TabPanel value='create'>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkUsername(username)) {
                    setError('');
                    handleCreate(username, new DaifugoRules({
                      n5skip, n7give, n8clear, n9reverse, n10burn, n11back, joker, tightSuits, tightRank, revolution
                    }));
                  } else {
                    setError('Username must be between 3 and 20 characters and contain only alphanumeric characters and underscores');
                  }
                }
              }}
            />
            {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
            <List
              sx={{ width: '100%' }}
              subheader="Game Rules"
            >
              <ListItem>
                {/* TODO: add nice <ListItemIcon>s */}
                <ListItemText primary="5 skips" secondary="Players can skip next player(s) by playing one or more 5s" />
                <Switch checked={n5skip} onChange={(e) => setN5skip(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="7 gives" secondary="Players can give up to k cards to the next player by playing k 7s" />
                <Switch checked={n7give} disabled={true} onChange={(e) => setN7give(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="8 clears" secondary="Players can clear the table by playing one or more 8s" />
                <Switch checked={n8clear} onChange={(e) => setN8clear(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="9 reverses" secondary="Players can reverse the playing direction by playing one or more 9s" />
                <Switch checked={n9reverse} onChange={(e) => setN9reverse(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="10 burns" secondary="Players can burn up to k cards by playing k 10s" />
                <Switch checked={n10burn} disabled={true} onChange={(e) => setN10burn(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="11 backs" secondary="Players can reverse the order by playing one or more Js" />
                <Switch checked={n11back} onChange={(e) => setN11back(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Jokers" secondary="Jokers are included in the deck" />
                <Switch checked={joker} disabled={true} onChange={(e) => setJoker(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Tight suits" secondary="Repeating suits will be locked (tight) for the round" />
                <Switch checked={tightSuits} onChange={(e) => setTightSuits(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Tight rank" secondary="Directly succeeding ranks will be locked (tight) for the round" />
                <Switch checked={tightRank} onChange={(e) => setTightRank(e.target.checked)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Revolution" secondary="Four cards of the same rank played together will reverse the playing order for the rest of the game" />
                <Switch checked={revolution} disabled={true} onChange={(e) => setRevolution(e.target.checked)} />
              </ListItem>
            </List>
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (tab === 'create') {
            handleCreate(username, new DaifugoRules({
              n5skip, n7give, n8clear, n9reverse, n10burn, n11back, joker, tightSuits, tightRank, revolution
            }));
          } else {
            handleJoin(username);
          }
        }}>{tab === 'create' ? 'Create' : 'Join'}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateOrJoinDialog;