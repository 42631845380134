import Game from 'shared/game_logic/game';
import Player from 'shared/game_logic/player';
import Card, { Suit } from 'shared/game_logic/card';
import React, { ReactElement, useEffect } from 'react';

const cardImageFiles = require.context('./assets/cards/', true, /\.png$/);

const cardImages = cardImageFiles.keys().sort((a, b) => {
  if (a === './back.png') return 1; // Back of the card should be last
  if (b === './back.png') return -1;
  // Extract the numbers from the file names
  const numA = parseInt(a.match(/\d+/)![0]);
  const numB = parseInt(b.match(/\d+/)![0]);

  // Compare the numbers
  return numA - numB;
}).map((image: string) => {
  return cardImageFiles(image);
});

const cardBack = cardImageFiles('./back.png');


interface GameTableProps {
  dimension: number;
  game: Game | undefined;
  onMove: (cards: number[]) => void;
  onHover?: (card: number) => void; //TODO: idea is to sync the hovered card as small animation to other players
  onCardSelect?: (card: number) => void; //TODO: same as above
}

enum Position {
  BOTTOM,
  LEFT,
  TOP,
  RIGHT,
}

interface HandProps {
  position: Position;
  tableDimension: number;
  game?: Game;
  player?: Player;
  selectedCards: number[];
  setSelectedCards?: (cards: number[]) => void;
}

function getPlayerPos(game: Game, playerUid: string): Position | undefined {
  if (game.player1.uid === playerUid) return Position.BOTTOM;
  if (game.player2?.uid === playerUid) return Position.LEFT;
  if (game.player3?.uid === playerUid) return Position.TOP;
  if (game.player4?.uid === playerUid) return Position.RIGHT;
  return undefined;
}

function SquaredGameTable({ dimension, game, onMove, onHover, onCardSelect }: GameTableProps): ReactElement {
  const td = dimension;

  const [selectedCards, setSelectedCards] = React.useState<number[]>([]);

  const [cardXOffsets, setCardXOffsets] = React.useState<number[]>([]);
  const [cardRotations, setCardRotations] = React.useState<number[]>([]);

  useEffect(() => {
    setSelectedCards([]);
    if (game?.table.cards.length === 0) {
      setCardXOffsets([]);
      setCardRotations([]);
    }
    if (game && game.table.cards.length > 0) {
      const newCardsCount = game.table.cards.length - cardXOffsets.length;
      const newCardXOffsets = cardXOffsets.slice();
      const newCardRotations = cardRotations.slice();
      const playedBy = game.table.playedBy;
      const tableOffset = game.table.cards.length - newCardsCount;
      var baseRotation = Number(getPlayerPos(game, playedBy[tableOffset])) * 90 + Math.random() * 20 - 10;
      for (let i = 0; i < newCardsCount; i++) {
        const xOffset = (i - (newCardsCount - 1) / 2) * .04 * td;
        const rotation = baseRotation + (i - (newCardsCount - 1) / 2) * 10 * Math.random();
        newCardXOffsets.push(xOffset);
        newCardRotations.push(rotation);
        if (playedBy[tableOffset + i + 1] !== playedBy[tableOffset + i]) {
          baseRotation = Number(getPlayerPos(game, playedBy[tableOffset + i + 1])) * 90 + Math.random() * 20 - 10;
        }
      };
      setCardXOffsets(newCardXOffsets);
      setCardRotations(newCardRotations);
    }
  }, [game]);

  return (
    <div className='gameTable'
      style={{
        width: `${td}px`,
        height: `${td}px`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid black',
      }}>
      {game?.player1 && <Hand position={Position.BOTTOM} tableDimension={td} game={game} player={game.player1} selectedCards={selectedCards} setSelectedCards={setSelectedCards} />}
      {game?.player2 && <Hand position={Position.LEFT} tableDimension={td} game={game} player={game.player2} selectedCards={[]} />} {/*TODO: show selected cards of other players*/}
      {game?.player3 && <Hand position={Position.TOP} tableDimension={td} game={game} player={game.player3} selectedCards={[]} />}
      {game?.player4 && <Hand position={Position.RIGHT} tableDimension={td} game={game} player={game.player4} selectedCards={[]} />}
      <div className='table' style={{
        position: 'absolute',
        width: `${.1 * td}px`,
        height: `${.1 * td}px`,
        bottom: `${.45 * td}px`,
        left: `${.45 * td}px`,
        border: '1px solid black',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: `${game?.currentPlayer?.playable ? 'pointer' : 'default'}`,
      }}
        onClick={() => {
          if (!game?.currentPlayer?.playable) return;
          onMove(game.player1.hand.filter((_, index) => selectedCards.includes(index)));
        }}
      >
        <div style={{
          width: `${.08 * td}px`,
          height: `${.08 * td}px`,
          backgroundColor: 'black',
          borderRadius: '10px',
        }}></div>
        {game?.table.cards.map((card, index) => {
          if (card === -1) return null; // 'skip' card
          return (
            <img
              className='card'
              src={card === -1 ? cardBack : cardImages[card]}
              alt={`card ${card}`}
              style={{
                width: `${.08 * td}px`,
                height: `${.08 * td * 1.5}px`,
                borderRadius: `${.08 * td * .1}px`,
                border: '1px solid black',
                position: 'absolute',
                transform: `rotate(${cardRotations[index]}deg) translateX(${cardXOffsets[index]}px)`,
              }}
              key={index}
            />
          )
        })}
      </div>
      <div className='tightInfo' style={{
        position: 'absolute',
        width: `${.1 * td}px`,
        height: `${.1 * td}px`,
        bottom: `${.35 * td}px`,
        left: `${.45 * td}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5em',
        color: 'black',
      }}>
        {game?.table.isTightRank && '#'}
        {game?.table.tightSuits.map((suit, index) => (
          <span key={index}>
            {suit === Suit.DIAMONDS ? '♦'
              : suit === Suit.HEARTS ? '♥'
                : suit === Suit.SPADES ? '♠'
                  : suit === Suit.CLUBS ? '♣'
                    : ''}
          </span>
        ))}
      </div>
    </div>
  );
}

function Hand({ position, tableDimension, game, player, selectedCards, setSelectedCards }: HandProps): ReactElement {
  const [hoveredCard, setHoveredCard] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (game?.currentPlayer?.uid !== player?.uid) {
      setHoveredCard(null);
      player?.deselectAll();
      if (setSelectedCards) setSelectedCards([]);
    }
  }, [game]);

  const td = tableDimension;

  const handWidth = .5 * td;
  const handHeight = .2 * td;

  var cardWidth = .08 * td;
  var cardHeight = .08 * td * 1.5;
  var horizontalStep = .05 * td;
  if (position !== Position.BOTTOM) {
    cardWidth *= .8;
    cardHeight *= .8;
    horizontalStep *= .8;
  }

  const totalCards = player?.hand.length || 0;
  const middleCardIndex = totalCards / 2;
  const cr = .9 * tableDimension; // radius of circle around which cards are arranged
  const bottomOffset = Math.sqrt(- ((horizontalStep * (- middleCardIndex) + .5 * cardWidth) ** 2) + cr ** 2);

  return (
    <div className={`${player?.playable ? 'playable-hand' : 'non-playable-hand'}`}
      style={{
        bottom: `${position === Position.BOTTOM ? `${.05 * td}px` : position === Position.RIGHT ? `${.25 * td}px` : 'auto'}`,
        top: `${position === Position.TOP ? `${.05 * td}px` : position === Position.LEFT ? `${.25 * td}px` : 'auto'}`,
        left: `${position === Position.LEFT ? `${.05 * td}px` : position === Position.BOTTOM ? `${.25 * td}px` : 'auto'}`,
        right: `${position === Position.RIGHT ? `${.05 * td}px` : position === Position.TOP ? `${.25 * td}px` : 'auto'}`,
        position: 'absolute',
        display: 'flex',
        width: `${handWidth}px`,
        height: `${handHeight}px`,
        transform: `rotate(${position === Position.BOTTOM ? 0 : position === Position.TOP ? 180 : position === Position.LEFT ? 90 : -90}deg)
                    translateY(${position === Position.LEFT || position === Position.RIGHT ? .5 * handWidth - .5 * handHeight : 0}px)
                    translateX(${position === Position.LEFT || position === Position.RIGHT ? .5 * handWidth - .5 * handHeight : 0}px)`,
      }}>
      {/* If player finished round, show 大富豪 / 富豪 / 貧民 / 大貧民 */}
      {game?.finishedRound.includes(player?.uid || '_') && <div style={{
        position: 'absolute',
        width: `${handWidth}px`,
        height: `${handHeight}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        fontSize: '1.5em',
        borderRadius: `${.1 * handHeight}px`,
      }}>
        {player?.uid === game?.finishedRound[0] ? '大富豪' : player?.uid === game?.finishedRound[1] ? '富豪' : player?.uid === game?.finishedRound[2] ? '貧民' : '大貧民'}
      </div>}
      {/* If player has skipped, show 'skips' */}
      {game && player && game.skipCount > 0 && game.skippedPlayers.includes(player.uid) && player.hand.length > 0 && <div style={{
        position: 'absolute',
        width: `${handWidth}px`,
        height: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1em',
        fontStyle: 'italic',
        borderRadius: `${.1 * handHeight}px`,
      }}>
        {game.gameRules.n5skip && game.table.currentRank === 5 && game.skippedPlayers.indexOf(player.uid) < game.table.playedTogether! ? 'skip' : 'pass'}
      </div>}
      {!game?.finishedRound.includes(player?.uid || '_') && player?.hand.sort((c1,c2) => Card.order(c1,c2)).map((card, index) => {
        const gradient = (index - middleCardIndex) * cardWidth / Math.sqrt(((index - middleCardIndex) * cardWidth + .5 * cardWidth) ** 2 + cr ** 2);
        const tilt = Math.atan(gradient) * (180 / Math.PI);
        const left = horizontalStep * (index - middleCardIndex);
        const bottom = Math.sqrt(- ((left + .5 * cardWidth) ** 2) + cr ** 2) - bottomOffset;

        return (
          <img
            key={index}
            className='card'
            src={card === -1 ? cardBack : cardImages[card]}
            alt={`card ${card}`}
            style={{
              position: 'absolute',
              width: `${cardWidth}px`,
              height: `${cardHeight}px`,
              left: `${.5 * handWidth + left}px`,
              bottom: `${hoveredCard === index || selectedCards.includes(index) ? bottom + .03 * td : bottom}px`,
              transform: `rotate(${tilt}deg) scale(${hoveredCard === index || selectedCards.includes(index) ? 1.1 : 1})`,
              zIndex: index,
              transition: 'all 0.1s ease',
              borderRadius: `${cardWidth * .1}px`,
              border: `${hoveredCard === index || selectedCards.includes(index) ? '2px solid black' : `2px solid hsl(${index * (360 / totalCards)}, 100%, ${player.isPlayersTurn ? '50%' : '90%'})`}`,
              cursor: player?.playable && player?.isPlayersTurn ? 'pointer' : 'default',
            }}
            onMouseEnter={() => player?.playable && player?.isPlayersTurn && setHoveredCard(index)}
            onMouseLeave={() => player?.playable && player?.isPlayersTurn && setHoveredCard(null)}
            onClick={() => {
              //TODO: onCardSelect
              if (!player?.playable || !player?.isPlayersTurn) return;
              if (selectedCards.includes(index) && setSelectedCards) {
                setSelectedCards(selectedCards.filter(card => card !== index));
                setHoveredCard(null);
              } else if (!selectedCards.includes(index) && setSelectedCards) {
                setSelectedCards([...selectedCards, index]);
              }
            }}
          />
        );
      })}
    </div>
  );
}

export default SquaredGameTable;